import React from 'react';

import { Box, Grid, Hidden, Theme, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import Body from '../../common/body/Body';
import FanGoBody from '../../common/body/FanGoBody';
import { FanGoGridCarousel } from '../../common/carousel';
import CustomGridCarousel from '../../common/carousel/CustomGridCarousel';
import CustomFanGoCard from '../../common/fanGoCard/CustomFanGoCard';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import FanGoTitle from '../../common/texts/FanGoTitle';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const responsiveSizes = [
  {
    breakpoint: 1200,
    cols: 3,
    rows: 3,
  },
  {
    breakpoint: 975,
    cols: 2,
    rows: 2,
  },
  {
    breakpoint: 650,
    cols: 2,
    rows: 1,
  },
  {
    breakpoint: 500,
    cols: 1,
    rows: 1,
  },
];

const SoftwareFeatures = ({ data, featureStyles, parentPage = '' }) => {
  const { title, productFeatures } = data;

  const cards = [];
  const midScreenFilteredCards = [];
  productFeatures.forEach((item, index) => {
    const { cardImage, _key, feature, description, internalLink } = item;
    const { asset, altText } = cardImage;
    const image = asset?.gatsbyImageData;

    const card = (
      <CustomFanGoCard
        minHeight="526px"
        key={_key}
        title={feature}
        image={image}
        altText={altText}
        text={description}
        internalLink={internalLink}
      />
    );
    cards.push(card);
    if (index < productFeatures.length - 1) {
      midScreenFilteredCards.push(card);
    }
  });

  return (
    <FanGoBody>
      <FanGoTitle title={title} />
      <SubText
        variant="subtitle1"
        fontColor={styles.grey}
        marginY={styles.allTwo}
        textAlignment={styles.alignStyleCCCC}
        value={<FanGoTextProcessor text={data._rawBody} textAlign="center" />}
      />
      <CustomGridCarousel
        itemsArray={cards}
        responsiveSizes={responsiveSizes}
        defaultCols={3}
        defaultRows={3}
        paddingBottom={2}
        speed={500}
      />
    </FanGoBody>
  );
};

export default SoftwareFeatures;
